@import url("https://use.typekit.net/xcp5yfj.css");
@import url("https://fonts.googleapis.com/css2?family=Gravitas+One&display=swap");

body {
  font-family: proxima-nova, sans-serif;
  background-color: white;
}

.font-quote {
  font-family: "Gravitas One", cursive;
}
